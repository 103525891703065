import { Injectable } from '@angular/core';

import { RestService } from '../core/rest.service';

import { objectToQueryString } from '../../common/utils';

import { environment } from '../../../environments/environment';

@Injectable()
export class StaffLicenseService {
  constructor(private readonly rest: RestService) {}

  getLicenses(params: any = {}): Promise<any> {
    const query = objectToQueryString(params);

    return this.rest.get(`${environment.apiUrl}/api/staff/license${query}`);
  }

  getLicenseById(id: number): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/staff/license/${id}`);
  }

  createLicense(payload: any): Promise<any> {
    return this.rest.post(`${environment.apiUrl}/api/staff/license`, payload);
  }

  updateLicense(licenseId: number, payload: any): Promise<any> {
    return this.rest.put(`${environment.apiUrl}/api/staff/license/${licenseId}`, payload);
  }

  deleteLicense(licenseId: number): Promise<any> {
    return this.rest.delete(`${environment.apiUrl}/api/staff/license/${licenseId}`);
  }
}
