import { Injectable } from '@angular/core';

import { RestService } from './rest.service';

import { objectToQueryString } from '../../common/utils';

import { environment } from '../../../environments/environment';

@Injectable()
export class LutService {
  constructor(private readonly rest: RestService) {}

  getLicenseBillingTypes(): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/core/lut/license-billing-type`);
  }

  getLicenseJurisdictionTypes(): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/core/lut/license-jurisdiction-type`);
  }

  getLicenseTypes(): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/core/lut/license-type`);
  }

  getInvoiceLineItems(params = {}): Promise<any> {
    const queryParams = objectToQueryString(params);

    return this.rest.get(`${environment.apiUrl}/api/core/lut/invoice-line-item${queryParams}`);
  }

  getInvoiceTypes(): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/core/lut/invoice-type`);
  }

  getStates(): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/core/lut/state`);
  }

  getTaskStatuses(): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/core/lut/task-status`);
  }

  getTaskTypes(): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/core/lut/task-type`);
  }
}
