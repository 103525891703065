import { Injectable } from '@angular/core';

import { RestService } from '../core/rest.service';

import { objectToQueryString } from '../../common/utils';

import { environment } from '../../../environments/environment';

@Injectable()
export class StaffTaskService {
  constructor(private readonly rest: RestService) {}

  getTasks(where = null): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/staff/task${objectToQueryString(where)}`);
  }

  getTaskById(id: number): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/staff/task/${id}`);
  }

  createTask(payload: any): Promise<any> {
    return this.rest.post(`${environment.apiUrl}/api/staff/task`, payload);
  }

  updateTask(id: number, payload): Promise<any> {
    return this.rest.put(`${environment.apiUrl}/api/staff/task/${id}`, payload);
  }

  deleteTask(id: number): Promise<any> {
    return this.rest.delete(`${environment.apiUrl}/api/staff/task/${id}`);
  }
}
