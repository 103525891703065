import { Injectable } from '@angular/core';

import { StaffTaskService } from '../staff/task.service';

import { APP_BASE_URL } from '../../common/constants';

import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AppNotificationService {
  public readonly notificationSubject: BehaviorSubject<any> = new BehaviorSubject<any>([]);

  constructor(private readonly taskService: StaffTaskService) {}

  async getNotifications(): Promise<void> {
    const notifications = [];

    const { data: tasks } = await this.taskService.getTasks();

    const unassignedTasks = tasks.filter((el) => !el.assignedToUser);
    if (unassignedTasks.length) {
      notifications.push({
        label: 'You have unassigned tasks',
        routerLink: `${APP_BASE_URL}/staff/tasks`,
      });
    }

    const tasksPastDue = tasks.filter((el) => el.dueDate && moment(el.dueDate).isBefore(moment()));
    if (tasksPastDue.length) {
      notifications.push({
        label: 'You have tasks past due',
        routerLink: `${APP_BASE_URL}/staff/tasks`,
      });
    }

    this.notificationSubject.next(notifications);
  }
}
