import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpErrorResponse } from '@angular/common/http';

import { MAT_CHECKBOX_DEFAULT_OPTIONS } from '@angular/material/checkbox';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogModule } from '@angular/material/dialog';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/radio';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS, MatSnackBarModule } from '@angular/material/snack-bar';

import { AppComponent } from './app.component';

import { AppNotificationService } from './services/app/notification.service';

import { AlertService } from './services/core/alert.service';
import { AuthService } from './services/core/auth.service';
import { BusinessService } from './services/core/business.service';
import { CalendarService } from './services/core/calendar.service';
import { JWTService } from './services/core/jwt.service';
import { LicenseService } from './services/core/license.service';
import { LocationService } from './services/core/location.service';
import { LutService } from './services/core/lut.service';
import { MemberService } from './services/core/member.service';
import { PaymentMethodService } from './services/core/payment-method.service';
import { RestService } from './services/core/rest.service';
import { TenantService } from './services/core/tenant.service';
import { UserService } from './services/core/user.service';

import { StaffBusinessService } from './services/staff/business.service';
import { StaffCalendarService } from './services/staff/calendar.service';
import { StaffLicenseService } from './services/staff/license.service';
import { StaffLocationService } from './services/staff/location.service';
import { StaffInvoiceService } from './services/staff/invoice.service';
import { StaffMemberService } from './services/staff/member.service';
import { StaffOrderService } from './services/staff/order.service';
import { StaffTaskService } from './services/staff/task.service';
import { StaffTenantService } from './services/staff/tenant.service';
import { StaffUserService } from './services/staff/user.service';

import { AppRoutingModule } from './app-routing.module';

import { environment } from '../environments/environment';

@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  constructor() {}

  extractError(error) {
    // Try to unwrap zone.js error.
    // https://github.com/angular/angular/blob/master/packages/core/src/util/errors.ts
    if (error && error.ngOriginalError) {
      error = error.ngOriginalError;
    }

    // We can handle messages and Error objects directly.
    if (typeof error === 'string' || error instanceof Error) {
      return error;
    }

    // If it's http module error, extract as much information from it as we can.
    if (error instanceof HttpErrorResponse) {
      // The `error` property of http exception can be either an `Error` object, which we can use directly...
      if (error.error instanceof Error) {
        return error.error;
      }

      // ... or an`ErrorEvent`, which can provide us with the message but no stack...
      if (error.error instanceof ErrorEvent) {
        return error.error.message;
      }

      // ...or the request body itself, which we can use as a message instead.
      if (typeof error.error === 'string') {
        return `Server returned code ${error.status} with body "${error.error}"`;
      }

      // If we don't have any detailed information, fallback to the request message itself.
      return error.message;
    }

    // Skip if there's no error, and let user decide what to do with it.
    return null;
  }

  handleError(error) {
    let extractedError = this.extractError(error) || 'Handled unknown error';

    // Capture handled exception and send it to Sentry.
    // const eventId = Sentry.captureException(extractedError);

    // When in development mode, log the error to console for immediate feedback.
    if (!environment.production) {
      console.error(extractedError);
    }
  }
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    MatDialogModule,
    MatSnackBarModule,
    AppRoutingModule,
  ],
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } },
    {
      provide: MAT_DIALOG_DEFAULT_OPTIONS,
      useValue: {
        hasBackdrop: true,
        width: '350px',
      },
    },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: {
        color: 'primary',
      },
    },
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: {
        color: 'primary',
      },
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: 'fill',
      },
    },

    AppNotificationService,

    AlertService,
    AuthService,
    BusinessService,
    CalendarService,
    JWTService,
    LicenseService,
    LocationService,
    LutService,
    MemberService,
    PaymentMethodService,
    RestService,
    TenantService,
    UserService,

    StaffBusinessService,
    StaffCalendarService,
    StaffInvoiceService,
    StaffLicenseService,
    StaffLocationService,
    StaffMemberService,
    StaffOrderService,
    StaffTaskService,
    StaffTenantService,
    StaffUserService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
