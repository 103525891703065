import { Injectable } from '@angular/core';

import { RestService } from '../core/rest.service';

import { objectToQueryString } from '../../common/utils';

import { environment } from '../../../environments/environment';

@Injectable()
export class StaffInvoiceService {
  constructor(private readonly rest: RestService) {}

  getInvoices(params: any = null): Promise<any> {
    const queryString = objectToQueryString(params);
    return this.rest.get(`${environment.apiUrl}/api/staff/invoice${queryString}`);
  }

  getInvoiceById(id: number): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/staff/invoice/${id}`);
  }

  getInvoiceLineItems(id: number): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/staff/invoice/${id}/line-items`);
  }

  generateInvoiceNumber(tenantId: number): Promise<any> {
    return this.rest.get(`${environment.apiUrl}/api/staff/invoice/!generateInvoiceNumber?tenantId=${tenantId}`);
  }

  createInvoice(payload: any): Promise<any> {
    return this.rest.post(`${environment.apiUrl}/api/staff/invoice`, payload);
  }

  transformProposalToInvoice(payload: any): Promise<any> {
    return this.rest.post(`${environment.apiUrl}/api/staff/invoice/!transformProposalToInvoice`, payload);
  }
}
