export const APP_BASE_URL = '/app';

export const SQL_DATE_FORMAT = 'YYYY-MM-DD';
export const SQL_DATETIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';

export const MOMENT_DATE_FORMAT = 'MMM d, YYYY';
export const MOMENT_DATETIME_FORMAT = 'MMM d, YYYY h:mm a';

export const NG_DATE_FORMAT = 'M/d/yy';
export const NG_DATETIME_FORMAT = 'M/d/yy HH:mm';

export const NG_NO_DATA = '-';

export const CONSTRAINTS = {
  RXJS_TIMER_INTERNAL: {
    FIFTEEN_SECONDS: 15000,
    ONE_MINUTE: 60000,
  },
};

export const SNACK_BAR_MESSAGES = {
  FORM_INVALID: 'Please complete all required fields',
  LOADING: 'One moment...',
};

export const PAYMENT_TYPE = {
  BANK: 'bank',
  CARD: 'card',
};

export const ENUMS = {
  LICENSE: {
    BILLING_TYPE: {
      LICENSE_MANAGEMENT: 'management',
      REMINDERS: 'reminders',
    },
  },
};
